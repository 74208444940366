@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --business-blue: #3eb28d;
  --business-dark-blue-rgb: 1, 85, 124;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
    --business-blue: #3eb28d;
    --business-dark-blue-rgb: 1, 85, 124;
  }
}

@font-face {
  font-family: "FoundrySterling Light";
  src: local("FoundrySterling Light"),url(./fonts/FoundrySterling-Light.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "FoundrySterling Medium";
  src: local("FoundrySterling Medium"),url(./fonts/FoundrySterling-Medium.ttf) format('truetype');
  font-display: swap;
}

*{
  font-family: "FoundrySterling Medium", serif;
  font-weight:400;
  letter-spacing: 0.5px;
  color: rgb(var(--foreground-rgb));
}
html{
  height  : 100%;
  overflow: hidden;
  position: relative;
}

body {
  color: rgb(var(--business-dark-blue-rgb));
  background: rgb(255,255,255);
  background-image: url(../public/img/LOGIN_APP_VERTICAL_1X.png);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  height  : 100%;
  overflow: auto;
  position: relative;
}

.business-blue{
  background-color: var(--business-blue);
}

.business-dark-blue-text{
  color: rgb(var(  --business-dark-blue-rgb));
}

.text-business-dark-blue-text{
  color: var(--business-blue);
}
.bg-business-dark-blue-text{
  background-color: rgb(var(  --business-dark-blue-rgb));
}
.only-pwa{
  visibility:hidden;
}
/*
h1:first-of-type{
  border-left: 5px solid var(--business-blue);
}
*/

#navbar {
  display:none;
}

@media all and (display-mode: standalone) {
  #navbar {
    display:flex;
  }
  .appnav-osx a{
    padding-bottom: calc(1.5rem + env(safe-area-inset-bottom))
  }
  .only-pwa{
    visibility:visible;
  }
}

.top-0-important{
  top:0px!important;
}

.app-body-margin-top{
  margin-top: 4rem!important;
}

nav a.active {
  background-color: var(--business-blue);
  color: white;
}

nav a svg {
  stroke:black;
}
nav a.active svg {
  stroke:white;
}

textarea
{
  width:100%;
}

.textwrapper
{
  border:1px solid #999999;
  margin:5px 0;
  padding:3px;
}